<template>
  <vs-popup class="holamundo config-popup  paymentSummaryPopup" title="Payment schedule" :active.sync="isOpen" @close="onClose">
    <vs-col vs-w="12" v-if="selectedPlan.currentPlan.productType == 'B2C_BNPL'">
      <vs-row class="flex justify-between pay-later-card items-start">
        <div class="flex flex-col justify-center items-center">
          <img :src="update" alt="update" />
          <span class="dark-blue text-xs mt-2"> Every</span>
          <span class="dark-blue text-xs">{{ `${weekOrMonthOrDay}` }}</span>
        </div>
        <div class="flex flex-col justify-center items-center">
          <img :src="playCircle" alt="playcircle" />
          <span class="dark-blue text-xs mt-2">Starts on</span>
          <span class="dark-blue text-xs">
            {{ paymentRequest.payLaterSchedule ? momentDateWithoutDay(paymentRequest.payLaterSchedule[0]) : "acceptance date" }}
          </span>
        </div>
        <div class="flex flex-col justify-center items-center">
          <img :src="stopCircle" alt="stopcircle" />
          <span class="dark-blue text-xs mt-2"> Ends after</span>
          <span class="dark-blue text-xs">
            {{ `${selectedPlan.numberOfPayments} payments` }}
          </span>
        </div>
      </vs-row>

      <vs-table :data="calculations.schedule" class="payment-schedule-table">
        <template slot="thead">
          <vs-th class="text-base font-medium" style="color: #757575">Payment date</vs-th>
          <vs-th class="text-base font-medium" style="color: #757575">Amount</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr" class="font-light text-base">{{ momentDateWithoutDay(tr.date) }}</vs-td>
            <vs-td :data="tr.label" class="sec-row-payment font-light text-base">
              $ {{ addZeroes(tr.amount) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="vs-table--content">
        <div class="flex justify-between">
          <span class="text-lg dark-blue">Total amount</span>
          <span class="text-lg dark-blue">
            $ {{ addZeroes(calculations.total.amount) }}
          </span>
        </div>
      </div>
    </vs-col>
    <vs-col class="w-full" v-else>
      <div class="gray mb-5 items-center flex">
        <div><img :src="calenderImage" alt="calenderImage" /></div>
        <p class="dark-blue text-base font-normal ml-4">
          Customer payment will be collected {{ selectedPlan.currentPlan.initialPeriod }} days after the acceptance date
        </p>
      </div>
      <div class="b2c">
        <vs-row class="flex justify-between">
          <span class="text-lg dark-blue">Customer pays</span>
          <span class="text-lg amount">
            $ {{ addZeroes(calculations.total.amount) }}
          </span>
        </vs-row>
        <vs-row class="mt-4">
          <span class="text-lg dark-blue">Merchant receives</span>
          <span class="text-lg amount">
            $ {{ addZeroes(calculations.disbursement.amount) }}
          </span>
        </vs-row>
      </div>
    </vs-col>
  </vs-popup>
</template>

<script>
import momentTz from "moment-timezone";

export default {
  props: {
    title: { type: String },
    isOpen: { type: Boolean },
    calculations: { type: Object },
    paymentRequest: { type: Object },
    onClose: { type: Function },
  },

  data() {
    return {
      weekOrMonthOrDay: "",
      update: require("@/assets/images/update.png"),
      playCircle: require("@/assets/images/play-circle.png"),
      stopCircle: require("@/assets/images/stop-circle.png"),
      calenderImage: require("@/assets/images/calender.png"),
    };
  },
  methods: {
    momentDate(date) {
      return this.customDateFormat(date, "DD MMM YYYY (dddd)");
    },

    momentDateWithoutDay(date) {
      return this.customDateFormat(date, "DD MMM YYYY");
    },

    momentWeekDay(date) {
      return this.customDateFormat(date, "dddd");
    },

    customDateFormat(date, dateFormat) {
      return momentTz(date).tz("Australia/Sydney").format(dateFormat);
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
  },
  mounted() {
    const frequency = this.paymentRequest && this.paymentRequest.selectedPaymentPlan ? this.paymentRequest.selectedPaymentPlan.paymentFrequency : null;

    switch(frequency) {
      case "WEEKLY":
        this.weekOrMonthOrDay = "Week";
        break;

      case "FORTNIGHTLY":
        this.weekOrMonthOrDay = "fortnight";
        break;

      case "DAILY":
        this.weekOrMonthOrDay = "Day";
        break;

      case "MONTHLY":
        this.weekOrMonthOrDay = "Month";
        break;
    }
  },
  computed: {
    selectedPlan() {
      let plan = null;

      if (this.paymentRequest && this.paymentRequest.selectedPaymentPlan) {
        plan = this.paymentRequest.selectedPaymentPlan;
      } else if (this.paymentRequest && this.paymentRequest.payLaterPlan && this.paymentRequest.payLaterPlan.length) {
        plan = this.paymentRequest.payLaterPlan[0];
      }

      return plan;
    }
  }
};
</script>

