<template>
  <vx-card class="accordin-card mt-8" id="communicationWidget">
    <div class="vx-card__body bsv p-0">
      <vs-collapse accordion>
        <vs-collapse-item open ref="communicationsCollapse">
          <div slot="header">
            <h3 class="font-normal text-lg md:text-xl lg:text-2xl">
              Communications
            </h3>
          </div>
          <div class="ds-wrapper">
            <h2 class="mb-4">Email</h2>
            <vs-table :data="emails" class="communication-table stripes">
              <template slot="thead">
                <vs-th style="width: 15%;">
                  <label class="text-base">Date</label>
                </vs-th>
                <vs-th style="width: 20%;">
                  <label class="text-base">From</label>
                </vs-th>
                <vs-th style="width: 20%;">
                  <label class="text-base">To</label>
                </vs-th>
                <vs-th>
                  <label class="text-base">Subject</label>
                </vs-th>
                <vs-th style="width: 5%;">
                  <label class="text-base"></label>
                </vs-th>
              </template>
              <template v-for="(data, index) in emails">
                <vs-tr :key="index">
                  <vs-td :data="data.dateFormatted">
                    {{ data.dateFormatted }}
                  </vs-td>
                  <vs-td :data="data.from">
                    {{ data.from }}
                  </vs-td>
                  <vs-td :data="data.to">
                    {{ data.to }}
                  </vs-td>
                  <vs-td :data="data.subject">
                    {{ data.subject }}
                  </vs-td>
                  <vs-td>
                    <a @click="openWindow(data)">View</a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

            <h2 class="mt-8 mb-4">SMS</h2>
            <vs-table :data="sms" class="communication-table stripes">
              <template slot="thead">
                <vs-th style="width: 15%;">
                  <label class="text-base">Date</label>
                </vs-th>
                <vs-th style="width: 10%;">
                  <label class="text-base">From</label>
                </vs-th>
                <vs-th style="width: 10%;">
                  <label class="text-base">To</label>
                </vs-th>
                <vs-th>
                  <label class="text-base">Message</label>
                </vs-th>
              </template>
              <template v-for="(data, index) in sms">
                <vs-tr :key="index">
                  <vs-td :data="data.dateFormatted">
                    {{ data.dateFormatted }}
                  </vs-td>
                  <vs-td :data="data.from">
                    {{ data.from }}
                  </vs-td>
                  <vs-td :data="data.to">
                    {{ data.to }}
                  </vs-td>
                  <vs-td :data="data.content">
                    <p v-html="formatContent(data.content)"></p>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </div>
  </vx-card>
</template>
<script>
export default {
  props: {
    communication: {
      type: Object,
    },
  },

  data() {
    return {
      appUrl: process.env.VUE_APP_ROOT_API,
      emails: [],
      sms: [],
    };
  },

  methods: {
    formatContent(data) {
      if (!data) {
        return data;
      }

      return data.replace(/\n/g, "<br>");
    },

    openWindow(data) {
      const url = `${this.appUrl}view-log/email/${data.customId}/${data._id}`;
      window.open(url, "", "popup=true");
    }
  },

  mounted() {
    this.emails = [...this.communication.email];
    this.sms = [...this.communication.sms];
  }
};
</script>