<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Hosted payment page</h3>
    <div class="grid wrap-span pb-4 md:pb-6 lg:pb-8">
      <div class="w-full">
        <div class="flex">
          <label>URL:</label> <span class="ml-2 text-base leading-tight block font-normal">{{ getFullUrl() }}</span>
        </div>
        <div class="flex mt-2" v-if="paymentRequestTemplate">
          <label>Template:</label>
          <span class="ml-2 text-base leading-tight block font-normal">
            <template v-if="showLink">
              <a @click="handleTemplateClick">{{ paymentRequestTemplate.requestTemplateId }}</a>
            </template>
            <template v-else>
              {{ paymentRequestTemplate.requestTemplateId }}
            </template>
            ({{ paymentRequestTemplate.templateName }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hppBaseUrl: process.env.VUE_APP_HPP_BASE_URL
      }
    },

    props: {
      externalId: {
        type: String
      },
      paymentRequestTemplate: {
        type: Object | null
      }
    },

    methods: {
      getFullUrl() {
        if (!this.paymentRequestTemplate) {
          return null;
        }

        const templateId = this.paymentRequestTemplate.requestTemplateId.replace("HP-", "").replace("RT-", "");

        return `${this.hppBaseUrl}${templateId}?extId=${this.externalId}`;
      },

      handleTemplateClick() {
        window.open(this.$router.resolve({ name: "hosted-page-edit",
          params: {
            id: this.paymentRequestTemplate._id
          },
        }).href, "_blank");
      }
    },

    computed: {
      user() {
        return this.$store.state.AppActiveUser;
      },

      showLink() {
        return ["admin", "staffAdmin"].includes(this.user.userType);
      }
    }
  }
</script>

