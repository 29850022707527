<template>
  <div class="mb-12">
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Additional funding requests</h3>
    <div class="ds-wrapper">
      <vs-table :data="topUps" class="communication-table stripes">
        <template slot="thead">
          <vs-th width="20%">
            <label class="text-normal">Date requested</label>
          </vs-th>
          <vs-th width="18%">
            <label class="text-normal">Last updated</label>
          </vs-th>
          <vs-th width="25%">
            <label class="text-normal">Amount/service fee</label>
          </vs-th>
          <vs-th width="18%">
            <label class="text-normal">Note</label>
          </vs-th>
          <vs-th width="25%">
            <label class="text-normal">Supporting docs</label>
          </vs-th>
          <vs-th width="5%">
            <label class="text-normal">Status</label>
          </vs-th>
          <vs-th width="5%" v-if="displayAction">
            <label class="text-normal">Action</label>
          </vs-th>
        </template>
        <template v-for="(data, index) in topUps">
          <vs-tr :key="index">
            <vs-td :data="data.createdAt">
              {{ data.createdAt }} <br />
              {{ data.createdAtTime }} <br />
              {{ data.createdByName }} <br />
            </vs-td>
            <vs-td :data="data.updatedAt">
              <template v-if="data.status != 'Pending'">
                {{ data.updatedAt }}<br />
                {{ data.updatedAtTime }} <br />
                {{ data.actionedByName }} <br />
              </template>
            </vs-td>
            <vs-td :data="data.amount">
              {{ formatCurrency(data.amount) }} <p v-if="displayFee(data)" class="inline-block">/ {{ formatCurrency(data.fee) }}</p><br/>
              <a v-if="data.linkedPaymentRequestId" @click="gotoDetailPage(data.linkedPaymentRequestId._id)">
                {{ data.linkedPaymentRequestId.paymentRequestId }}
              </a>
            </vs-td>
            <vs-td :data="data.notes">
              {{ data.notes }}
            </vs-td>
            <vs-td :data="data.attachments">
              <div v-if="data.attachments && data.attachments.length" v-for="(attachment, key) in data.attachments" class="mb-4">
                <S3FileGenerator :document="attachment" :key="key" />
              </div>
              <a v-if="showUploadButton(data)" class="flex gap-x-2 items-center mt-2" @click="handleFileUpload(data._id)">
                <plus-icon size="1x" class="custom-class"></plus-icon>Upload file
              </a>
            </vs-td>
            <vs-td :data="data.status">
              <div class="flex align-middle">
                <vs-chip :color="changeStatusColor(data.status)" size="small">{{ data.status }}</vs-chip>
              </div>
            </vs-td>
            <vs-td v-if="displayAction">
              <a v-if="displayCancel(data)" @click="handleCancelClick(data._id)">Cancel</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <vs-popup class="holamundo" title="Cancel additional funding" :active.sync="cancelPopUp">
      <p>You're about to cancel the request for additional funding - are you sure?</p>

      <div class="popup-action-btn-container flex justify-end">
        <vs-button class="mr-5" @click="processCancelTopUp" action-btn size="large">Yes</vs-button>
        <vs-button @click="cancelPopUp = false" action-btn color="danger" size="large">No</vs-button>
      </div>
    </vs-popup>

    <vs-popup class="holamundo" title="Upload additional file" :active.sync="fileUploadPopUp">
      <p>Please attach file</p>

      <div class="flex flex-col lg:flex-row w-full mt-4">
        <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
          <label class="w-full text-base font-light text-dark-blue block pb-1">Supporting docs (PDF only)</label>
          <attachment
            ref="attachmentComponent"
            :button-label="'Select a file'"
            :showDescription="false"
            @updateFiles="fileUpdate"
           />
           <span v-if="showFileUploadWarning" class="text-danger text-sm">Please select at least one file</span>
        </div>
      </div>

      <div class="popup-action-btn-container flex justify-end">
        <vs-button class="mr-5" @click="processTopUpFileUpload" action-btn size="large">Continue</vs-button>
        <vs-button @click="fileUploadPopUp = false" action-btn color="danger" size="large">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import S3FileGenerator from "@/views/components/S3FileGenerator";
  import { PlusIcon } from "vue-feather-icons";

  import Attachment from "../../../pages/paymentRequests/Attachment.vue";

  export default {
    components: {
      Attachment,
      PlusIcon,
      S3FileGenerator
    },

    props: {
      topUps: {
        type:  Array,
        default: []
      },
      productConfigType: { type: String },
    },

    data() {
      return {
        cancelPopUp: false,
        fileUploadPopUp: false,
        supportingDocuments: [],
        topUpId: "",
        showFileUploadWarning: false,
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["cancelTopUp", "topUpFileUpload"]),

      handleCancelClick(topUpId) {
        this.topUpId = topUpId;
        this.cancelPopUp = true;
      },

      async processCancelTopUp() {
        this.$vs.loading();

        await this.cancelTopUp(this.topUpId).then(result => {
          this.$vs.loading.close();
          this.showMessage("Success", "Top-up cancelled successfully.", "success", "icon-check-circle");
          this.cancelPopUp = false;
          this.$emit("update");
        }).catch(ex => {
          this.$vs.loading.close();
          this.showMessage("Failed", ex.data.message, "danger", "icon-times-circle");
        });
      },

      changeStatusColor(status) {
        let bgClass = "";

        switch (status) {
          case "Approved":
          case "Paid":
            bgClass = "success";
            break;

          case "Cancelled":
            bgClass = "secondary-500";
            break;

          case "Declined":
            bgClass = "error";
            break;

          case "Submitted":
            bgClass = "primary";
            break;

          default:
            bgClass = "primary";
            break;
        }

        return bgClass;
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup");
        }
      },

      showUploadButton(data) {
        return ["Pending", "Submitted"].includes(data.status);
      },

      handleFileUpload(topUpId) {
        this.topUpId = topUpId;
        this.fileUploadPopUp = true;
      },

      async processTopUpFileUpload() {
        if (!this.supportingDocuments.length) {
          this.showFileUploadWarning = true;
          return;
        }

        this.$vs.loading();

        const formData = new FormData();
        formData.append("topUpId", this.topUpId);

        for (let i = 0; i < this.supportingDocuments.length; i++) {
          formData.append("attachments", this.supportingDocuments[i]);
        }

        const obj = {
          obj: formData,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        await this.topUpFileUpload(obj).then((result) => {
          this.$vs.loading.close();
          this.showMessage("Success", "Funding requested successfully.", "success", "icon-check-circle");
          this.fileUploadPopUp = false;
          this.$emit("update");
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Failed", ex.data.message, "danger", "icon-times-circle");
        });
      },

      fileUpdate(files) {
        this.supportingDocuments = files;

        if (this.showFileUploadWarning) {
          this.showFileUploadWarning = false;
        }
      },

      gotoDetailPage(id) {
        let routeData = this.$router.resolve({
          name: "staff-payment-requests-detail",
          params: {
            id: id,
          },
        });
        window.open(routeData.href, "_blank");
      },

      displayCancel(data) {
        return ["Pending"].includes(data.status) || ((this.productConfigType == "THREE_PARTY") && ["Declined"].includes(data.status));
      },

      displayFee(data) {
        return ["Approved"].includes(data.status) && parseFloat(data.fee) > 0;
      }
    },

    watch: {
      cancelPopUp(val) {
        this.addPopUpClass(val);

        if (!val) {
          this.topUpId = "";
        }
      },

      fileUploadPopUp(val) {
        this.addPopUpClass(val);

        if (!val) {
          this.topUpId = "";
          this.supportingDocuments = [];
          this.$refs.attachmentComponent.clearFiles();
          this.errors.clear();
        }
      }
    },

    computed: {
      displayAction() {
        return this.topUps.some(item => ["Pending"].includes(item.status) || ((this.productConfigType == "THREE_PARTY") && ["Declined"].includes(item.status)));
      }
    }
  }
</script>

