<template>
  <div>
    <div v-for="(product, productIndex) in localData" :key="product.productId">
      <h3 v-if="product.productCustomFields.length" class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Additional information (to access {{ product.productName }} {{ product.productId }})</h3>
      <div v-if="product.productCustomFields.length" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10 pr-detail-fields">
        <div v-for="(customField, key) in product.productCustomFields" :key="key" class="w-full">
          <div class="custom-fields w-full" v-if="(customField.displayToMerchantAfter || customField.editableByMerchantAfter || customField.editableByMerchant || showInfoRequiredFiles(customField))">
            <h3 class="font-normal text-base flex">
              {{ customField.label }}
              <span class="ml-4 flex-1">
                <img
                  v-if="showEditIcon(customField)"
                  @click="openEditMode(productIndex, key, customField.value, customField.type)"
                  :src="editIcon"
                  class="cursor-pointer"
                />
              </span>
            </h3>
            <p class="mt-2" v-if="customField.type !== 'address'">
              <S3FileGenerator v-if="customField.type === 'file'" :document="customField.displayValue" :key="customField.displayValue" />
              <span v-else>
                <p class="mt-2" v-if="customField.type === 'currency'">$ {{ customField.displayValue }}</p>
                <p class="mt-2" v-else-if="customField.type === 'number'">{{ customField.displayValue }}</p>
                <p class="mt-2" v-else-if="customField.type === 'date'">{{ customField.displayValue ? formattedDate(customField.displayValue) : customField.displayValue }}</p>
                <p class="mt-2" v-else>{{ customField.displayValue }}</p>
              </span>
            </p>
            <div v-if="customField.type === 'address'" class="mb-4">
              <p class="mt-2" v-html="formatAddress(customField.displayValue)"></p>
            </div>
          </div>
          <div v-if="customField.isOpen" class="custom-fields w-full">
            <date-picker
              v-if="customField.type === 'date'"
              valueType="format"
              v-model="customField.value"
              lang="en"
              type="date"
              placeholder=""
              class="w-auto"
              format="YYYY-MM-DD"
            ></date-picker>

            <template v-else-if="customField.type === 'currency'">
              <money
                v-validate="{
                  required: customField.reqToSendRequest ? true : false,
                }"
                :name="`${customField.label}`"
                v-model="customField.value"
                class="w-full"
                v-bind="moneyMask"
              />
              <span class="text-danger text-sm" v-show="errors.has(`${customField.label+productIndex}`)">{{ errors.first(`${customField.label+productIndex}`) }}</span>
            </template>

            <template v-else-if="customField.type === 'file'">
              <input
                :ref="`uploadImgInput${productIndex}${key}`"
                type="file"
                class="hidden"
                :name="customField.label+productIndex"
                v-validate="{ required : true, size: maxFileSize * 1024 }"
                @change="updateCurrImg($event, key, productIndex)"
                accept=".pdf"
              />
              <div class="text-danger text-sm pt-1" v-show="errors.has(`${customField.label+productIndex}`)">
                {{ errors.first(`${customField.label+productIndex}`) }}
              </div>
              <vs-button flat class="only-border-btn small" small v-round @click="uploadImgInput(productIndex, key)">Upload</vs-button>
              <span v-if="customField.file">{{ customField.file.name }}</span>
            </template>

            <vs-select v-else-if="customField.type === 'dropdown'" class="selectExample" v-model="customField.value">
              <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in customField.dropdownSelectFields"/>
            </vs-select>

            <template v-else-if="customField.type === 'address'">
              <label class="text-base">{{ customField.label }} <span v-if="customField.reqToSendRequest" class="mid-blue">*</span></label>
              <vs-row class="my-5">
                <vs-col vs-w="7">
                  <vue-google-autocomplete
                    ref="address"
                    :id="customField._id"
                    :value="productAddressFieldArray[customField._id].rawAddress"
                    classname="w-full custom-w"
                    v-on:placechanged="getAddressForProductField"
                    :country="['au']"
                    placeholder="Start typing your address"
                  >
                  </vue-google-autocomplete>
                </vs-col>
              </vs-row>
              <vs-row class="mb-5">
                <vs-col vs-w="12" class="custom-mt pr-xs-0 lg:mt-0 sm:mt-5">
                  <label class="text-base font-light w-full">Apt, Suite, Unit, Building, Floor</label>
                  <vs-input class="w-full custom-w" v-model="productAddressFieldArray[customField._id].unit_number" :name="'unit_number'+productIndex" />
                  <span class="text-danger text-sm" v-show="errors.has('unit_number'+productIndex)">{{ errors.first('unit_number'+productIndex) }}</span>
                </vs-col>
              </vs-row>
              <vs-row class="mb-5">
                <div>
                  <label class="text-base font-light">Suburb</label>
                  <div class="input-container">
                    <span class="text-danger text-sm" v-show="errors.has('suburb'+productIndex)">{{ errors.first('suburb'+productIndex) }}</span>
                    <vs-input class="w-full custom-w" v-model="productAddressFieldArray[customField._id].suburb" :name="suburb+productIndex" v-validate="'required'" />
                  </div>
                </div>
              </vs-row>

              <vs-row class="mb-5">
                <vs-col vs-w="12" class="custom-mt mt-5">
                  <label class="text-base font-light w-auto">Street address</label>
                  <div class="input-container">
                    <span class="text-danger text-sm" v-show="errors.has('street_name'+productIndex)">{{ errors.first('street_name'+productIndex) }}</span>
                    <vs-input class="w-full custom-w add-street" v-model="productAddressFieldArray[customField._id].street_name" :name="'street_name'+productIndex" v-validate="'required'" />
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="mb-5">
                <div class="custom-mt-sm pr-5 pr-xs-0">
                  <label class="w-full text-base font-light">State</label>
                  <div class="input-container">
                    <span class="text-danger text-sm" v-show="errors.has('state'+productIndex)">{{ errors.first("state"+productIndex) }}</span>
                    <vs-select
                      autocomplete
                      v-model="productAddressFieldArray[customField._id].state"
                      class="w-full custom-w"
                      :name="'state'+productIndex"
                      v-validate="'required'"
                      laceholder="Select state"
                    >
                      <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states" ></vs-select-item>
                    </vs-select>
                  </div>
                </div>
              </vs-row>

              <vs-row class="mb-5">
                <div class="custom-mt-sm">
                  <label class="w-full text-base font-light">Postcode</label>
                  <div class="input-container">
                    <span class="text-danger text-sm" v-show="errors.has('postcode'+productIndex)">{{ errors.first("postcode"+productIndex) }}</span>
                    <the-mask
                      class="w-full custom-w vs-input--input"
                      type="text"
                      masked
                      :mask="['####']"
                      v-model="productAddressFieldArray[customField._id].postcode"
                      :name="'postcode'+productIndex"
                      v-validate="'required'"
                    />
                  </div>
                </div>
              </vs-row>
            </template>
            <vs-input v-else v-model="customField.value"></vs-input>
            <vs-row class="flex mt-2">
              <vs-button
                class="primary flex items-center justify-center lg:mr-8 mr-4"
                size="small"
                v-validate="'required'"
                @click="saveProductField(productIndex, key)"
                v-round
                >Save</vs-button
              >
              <div class="mid-blue cursor-pointer" @click="cancelEditMode(productIndex, key)"><u>Cancel</u></div>
            </vs-row>
          </div>
          <div class="custom-fields w-full" v-if="((customField.displayToMerchantAfter === true && customField.value) || customField.editableByMerchantAfter === true || showInfoRequiredFiles(customField))">
            <a @click="openAuditLogPopUp(customField)" v-if="customField.auditLogs">
              <span v-for="(logDetail, key) in customField.auditLogs" :key="key">
                <span v-if="key == 0">Last updated on {{ getLastUpdatedDate(customField.auditLogs) }}</span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <vs-popup class="holamundo" title="Product field name audit log" :active.sync="auditLogPopup">
        <div v-for="(auditLog, key) in activeAuditLog" :key="key">
          <h3>Updated by {{ auditLog.updateBy }}</h3>
          <div>
            Image
            <p>{{ auditLog.userFullname }}</p>
            <p>
              {{ moment(auditLog.createdDate) }} at
              {{ getTime(auditLog.createdDate) }}
            </p>
          </div>
          <p>Old Value</p>
          <h5>{{ auditLog.oldValue }}</h5>
          <p>New Value</p>
          <h5>{{ auditLog.newValue }}</h5>
        </div>
        <div class="flex-end mt-5 flex-end">
          <vs-button @click="close" class="mr-5">Close</vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import S3FileGenerator from "@/views/components/S3FileGenerator";
import { sentryLog } from "../../../../../../helper/sentryLog";

export default {
  name: "ProductFields",
  components: {
    S3FileGenerator,
    DatePicker,
    TheMask,
    VueGoogleAutocomplete,
    Money,
  },
  props: {
    productFields: { type: Array },
    paymentStatus: { type: String },
    customPrId: { type: String },
  },
  data() {
    return {
      auditLogPopup: false,
      activeAuditLog: [],
      editIcon: require('@/assets/images/edit.png'),
      localData: [],
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      prId: "",
      productAddressFieldArray: {},
      productAddressField: {
        rawAddress: "",
        index: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
      },
      baseUrl: process.env.VUE_APP_API_URL,
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["saveProductFieldInPr"]),
    openEditMode(productIndex, key, value, type) {
      this.localData[productIndex].productCustomFields[key].isOpen = !this.localData[productIndex].productCustomFields[key].isOpen;
      this.localData[productIndex].productCustomFields[key].oldValue = value;
    },

    cleanAddress(val) {
      let productAddressField = JSON.parse(val);

      // [StreetNo] [StreetName}, [Suburb], [State] [Postcode], [Country]
      let address ="";
      if (productAddressField.street_number) {
        address = `${productAddressField.street_number} `
      }

      if (productAddressField.street_name) {
        address = `${productAddressField.street_name},`
      }

      if (productAddressField.suburb) {
        address+=` ${productAddressField.suburb}`
      }

      if (productAddressField.state) {
        address+=`, ${productAddressField.state}`
      }

      if (productAddressField.postcode) {
        address+=`, ${productAddressField.postcode}`
      }

      if (productAddressField.country) {
        address+=`, ${productAddressField.country}`
      }

      return address
    },

    formatAddress(val){
      if(!val) return val;
      let obj = JSON.parse(val);
      let address = "";
      if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
        address = obj.unit_number + "<br>";
      }

      if (obj.street_name && Object.keys(obj.street_name).length > 0) {
        address = address + obj.street_name + "<br>";
      } else if(obj.street && Object.keys(obj.street).length > 0) {
        address = address + obj.street + "<br>";
      }

      if (obj.suburb && Object.keys(obj.suburb).length > 0) {
        address = address + obj.suburb + " ";
      }

      if (obj.state && Object.keys(obj.state).length > 0) {
        address = address + obj.state;
        if (Object.keys(obj.postcode).length > 0) {
          address = address + " " + obj.postcode + "<br>";
        } else {
          address = address + "<br>";
        }
      }

      if (obj.country && Object.keys(obj.country).length > 0) {
        address = address + obj.country;
      } else {
        address = address + "Australia";
      }

      return address;
    },

    addZeroes(num) {
      num = String(num);
      if (num.length === 0) {
        return "";
      }

      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    getAddressForProductField: function (addressData, placeResultData, _id) {
      this.addressData = addressData;

      if (this.addressData.street_number) {
        this.productAddressFieldArray[_id].street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.productAddressFieldArray[_id].postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.productAddressFieldArray[_id].suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.productAddressFieldArray[_id].state = this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";
        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
          }
        }
        this.productAddressFieldArray[_id].street_name = street_address;
      }
    },

    async saveProductField(productIndex, key) {
      const saveData = this.localData[productIndex].productCustomFields[key];

      if (saveData.type === "address") {
        saveData.value = JSON.stringify(this.productAddressFieldArray[saveData._id]);
      }

      saveData.paymentRequestId = this.prId;
      saveData.customPrId = this.customPrId;
      saveData.productId = this.localData[productIndex].productId;
      saveData.productIndex = productIndex;
      saveData.fieldIndex = key;
      const fd = new FormData();
      fd.append("file", saveData.file);
      fd.append("form", JSON.stringify(saveData));

      const obj = {
        data: fd,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };

      this.$vs.loading();

      await this.saveProductFieldInPr(obj).then((response) => {
        this.$vs.loading.close();
        this.setDataAsLocal(response.data.data.productCustomFields);

        if (response.data.data.paymentStatus) {
          this.$emit("update");
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },

    setDataAsLocal(productFields) {
      this.localData = productFields.map((item) => {
        let newFields = [];
        let productCustomFields = item.productCustomFields || item.customFields;

        productCustomFields.filter((field) => {
          if (this.displayToMerchant(field)) {

            if (field.type == "currency" && field.value) {
              field.value = field.value.replace("$ ", "");
              field.value = this.addZeroes(field.value);
            }

            newFields.push(field);
          }
        });

       return { ...item, productCustomFields: newFields.length ? this.addNewFields(productCustomFields) : [] };
      });
    },

    cancelEditMode(productIndex, key) {
      this.localData[productIndex].productCustomFields[key].isOpen = false;
    },

    getLastUpdatedDate(auditLog) {
      return moment(auditLog[auditLog.length -1].createdDate).format("D MMMM YYYY");
    },

    moment(date) {
      return moment(date).format("D MMMM YYYY");
    },

    getTime(date) {
      return moment(date).format("HH:mm");
    },

    uploadImgInput(productIndex, key) {
      this.$refs[`uploadImgInput${productIndex}${key}`][0].click();
    },

    updateCurrImg(input, key, productIndex) {
      this.localData[productIndex].productCustomFields[key].file = input.target.files[0];
    },

    openAuditLogPopUp(clickedProductField) {
      this.auditLogPopup = true;
      this.activeAuditLog = clickedProductField.auditLogs;
    },

    close() {
      this.auditLogPopup = false;
      this.activeAuditLog = [];
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    addNewFields(productFields) {
      return productFields.map((field) => {
        return {
          ...field,
          isOpen: false,
          oldValue: "",
          file: null,
          displayValue: field.value
        }
      })
    },

    displayToMerchant(customField) {
      return (customField.displayToMerchantAfter || customField.editableByMerchantAfter || customField.editableByMerchant);
    },

    showEditIcon(customField) {
      return ((customField.editableByMerchantAfter && ["Active", "Pending", "Submitted"].includes(this.paymentStatus)) || this.showInfoRequiredFiles(customField));
    },

    showInfoRequiredFiles(customField) {
      return (customField.type == "file" && customField.reqToSendRequest && ["Info-required"].includes(this.paymentStatus));
    }
  },

  created() {
    try {
      this.setDataAsLocal(this.productFields);
      this.prId = this.$route.params.id;

      for (let i = 0; i < this.localData.length; i++) {
        let customFields = this.localData[i].productCustomFields;

        for (let j = 0; j < customFields.length; j++) {
          if (customFields[j].type === "address") {
            try {
              this.productAddressField = JSON.parse(customFields[j].value);
              this.productAddressField.rawAddress = `${this.productAddressField.street_name},${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
              this.productAddressFieldArray[customFields[j]._id] = this.productAddressField;
            } catch (ex) {
              this.productAddressField.rawAddress = `"","" "", Australia`;
              this.productAddressFieldArray[customFields[j]._id] = this.productAddressField;
            }
          }
        }
      }
    } catch (ex) {
      sentryLog(ex);
    }
  },
};
</script>

