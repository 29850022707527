<template>
  <div>
    <vs-alert :active="paymentInProgress" color="success" icon="check_circle_outline" class="mb-4 single-line-alert" v-if="paymentInProgress">
      <span>{{ transactionText }}</span>
    </vs-alert>
    <vx-card class="accordin-card">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open ref="detailsCollapse">
            <div slot="header">
              <a v-if="paymentRequestDetail.selectedPlanConfig && paymentRequestDetail.selectedPlanConfig.statementOfAccount && paymentRequestDetail.selectedPlanConfig.statementOfAccount.soaUrl" target="_blank" :href="paymentRequestDetail.selectedPlanConfig.statementOfAccount.soaUrl" class="no-underline float-right flex pt-1 text-md pr-8">
                <vs-icon size="small" icon="description" class="mr-2"></vs-icon> Statement
              </a>
              <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Summary</h3>
            </div>
            <div class="px-0 pt-0 md:pt-2 md:px-6 lg:px-8">
              <default-display-details
                :paymentRequestDetail.sync="paymentRequestDetail"
                :cancelPaymentRequest="cancelPaymentRequest"
                :resendPaymentRequest="resendPaymentRequest"
                :calculations="calculations"
                :remainingAmount="remainingAmount"
                :productConfigType.sync="productConfigType"
                :updatePaymentRequest="updatePaymentRequest"
                v-on="$listeners"
              />
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>
    <vx-card class="accordin-card mt-8">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open ref="detailsCollapse">
            <div slot="header">
              <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Details</h3>
            </div>
            <top-up-list v-if="paymentRequestDetail && paymentRequestDetail.topUps && paymentRequestDetail.topUps.length > 0" :topUps="paymentRequestDetail.topUps" :productConfigType="productConfigType" v-on="$listeners" />
            <ProductCustomFields
              :productFields="paymentRequestDetail.productFields"
              :paymentStatus="paymentRequestDetail.paymentStatus"
              :customPrId="paymentRequestDetail.paymentRequestId"
              v-on="$listeners"
              v-if="checkProductFieldsDisplay()"
            />
            <custom-fields v-if="checkCustomFieldsDisplay()" :customFields.sync="paymentRequestDetail.customFields" />
            <attached-files v-if="paymentRequestDetail.files.length" :files.sync="paymentRequestDetail.files" />
            <repayment-option :payment-request-detail="paymentRequestDetail" />
            <external-id v-if="paymentRequestDetail.externalId" :external-id="paymentRequestDetail.externalId" :payment-request-template="paymentRequestDetail.paymentRequestTemplate && paymentRequestDetail.paymentRequestTemplate.length ? paymentRequestDetail.paymentRequestTemplate[0] : null" />
            <history :paymentRequest.sync="paymentRequestDetail" />
            <legal v-if="productConfigType == 'TWO_PARTY'" :paymentMethodTerms="paymentRequestDetail.paymentMethodTerms" :privacy="paymentRequestDetail.privacy" :terms="paymentRequestDetail.terms"></legal>
            <contact :paymentRequest.sync="paymentRequestDetail" v-if="productConfigType == 'THREE_PARTY'" />
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <communications v-if="showCommunication" :communication="paymentRequestDetail.communication" />
  </div>
</template>

<script>
import DefaultDisplayDetails from "./DefaultDisplayDetails.vue";
import CustomFields from "./CustomFields.vue";
import AttachedFiles from "./AttachedFiles.vue";
import Contact from "./Contact.vue";
import History from "./History.vue";
import Legal from "./Legal.vue";
import ProductCustomFields from "./ProductFields.vue";
import RepaymentOption from "./RepaymentOption.vue";
import ExternalId from "./ExternalId.vue";
import Communications from  "./Communications.vue";
import TopUpList from "./TopUpList.vue";

export default {
  components: {
    AttachedFiles,
    Communications,
    Contact,
    CustomFields,
    DefaultDisplayDetails,
    ExternalId,
    History,
    Legal,
    ProductCustomFields,
    TopUpList,
    RepaymentOption
  },

  props: {
    paymentRequestDetail: { type: Object },
    calculations: { type: Object },
    isAdminLogin: { type: Boolean },
    markPaymentAsCancelled: { type: Function },
    resendPaymentRequest: { type: Function },
    remainingAmount: { type: Number },
    productFields: { type: Object },
    productConfigType: { type: String },
    updatePaymentRequest: { type: Function },
  },

  data() {
    return {
      transactionText: ""
    }
  },

  methods: {
    async cancelPaymentRequest(data) {
      return await this.markPaymentAsCancelled(data);
    },

    checkProductFieldsDisplay() {
      return (this.paymentRequestDetail.productFields && this.paymentRequestDetail.productFields.length);
    },

    checkCustomFieldsDisplay() {
      if (!this.paymentRequestDetail.customFields) {
        return false;
      }
      let check = 0;

      this.paymentRequestDetail.customFields.find((item) => {
        if (item.value) {
          check++;
        }
      });

      return (check > 0);
    },
  },

  computed: {
    showCommunication() {
      const selectedPlan = this.paymentRequestDetail.selectedPlanConfig;
      const display = !this.paymentRequestDetail.selectedPaymentPlan || (selectedPlan && (selectedPlan.showTransactionBalance || selectedPlan.productConfigType == "TWO_PARTY"));

      return (display && this.paymentRequestDetail.communication);
    },

    paymentInProgress() {
      let display = false;
      const selectedPlan = this.paymentRequestDetail.selectedPlanConfig;

      if (selectedPlan && (selectedPlan.showTransactionBalance || selectedPlan.productConfigType == "TWO_PARTY")) {
        const transactionInProgress = this.paymentRequestDetail.transactions.find((item) => item.type == "Payment" && ["Processing", "Queued"].includes(item.status));

        if (transactionInProgress) {
          display = true;
          this.transactionText = `Direct debit for ${transactionInProgress.amountFormatted} in progress`;

          if (transactionInProgress.waitingOnClearedFundsDate) {
            this.transactionText = `${this.transactionText}, expected to complete by ${transactionInProgress.waitingOnClearedFundsDate}`;
          }
        }
      }

      return display;
    }
  }
};
</script>
