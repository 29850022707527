<template>
  <vs-popup class="holamundo" title="Request additional funding" :active.sync="topUpPopUp">
    <p>{{ product.merchantTopUp.description || "" }}</p>
    <div class="w-full mt-4">
      <div class="flex flex-col lg:flex-row w-full">
        <div class="mb-4 md:mb-6 w-1/3 md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
          <label for="additionalFundAmount" class="w-full text-base font-light text-dark-blue block pb-1">Amount requested <span class="mid-blue">*</span></label>
          <div class="flex items-center relative">
            <span class="pr-1 absolute l-0 t-0 text-dark-blue">$</span>
            <money class="moneyPR w-full pl-5 font-light" v-model="additionalFundAmount" name="additionalFundAmount" :precision="2" :placeholder="'Amount'" id="additionalFundAmount" v-validate="'requiredAmount'"></money>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('additionalFundAmount')">{{ errors.first("additionalFundAmount") }}</span>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row w-full">
        <div class="mb-4 md:mb-6 w-full ">
          <label for="note" class="w-full text-base font-light text-dark-blue block pb-1">Reason for increase (add other notes as relevant) <span class="mid-blue">*</span></label>
          <div class="flex items-center relative md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
            <vs-textarea
              v-model="note"
              rows="2"
              id="custom-textarea custom-message"
              name="note"
              class="w-full"
              v-validate="'required'"
              style="max-width:330px;"
              @input="auto_grow('custom-textarea')"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('note')">{{ errors.first("note") }}</span>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row w-full">
        <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
          <label class="w-full text-base font-light text-dark-blue block pb-1">Supporting docs (PDF only)</label>
          <attachment
            ref="attachmentComponent"
            :button-label="'Select a file'"
            :showDescription="false"
            @updateFiles="fileUpdate"
           />
        </div>
      </div>
    </div>
    <div class="popup-action-btn-container flex justify-end">
      <vs-button class="mr-5" action-btn @click="handleSubmit" size="large">Continue</vs-button>
      <vs-button @click="handleCancel" action-btn color="danger" size="large">Cancel</vs-button>
    </div>
  </vs-popup>
</template>

<script>
  import { mapActions } from "vuex";
  import VeeValidate from "vee-validate";
  import { Money } from "v-money";

  import Attachment from "../../../pages/paymentRequests/Attachment.vue";

  export default {
    components: {
      Attachment,
      Money,
    },

    props: {
      product: {
        type: Object
      },
      prId: {
        type: String
      }
    },

    data() {
      return {
        additionalFundAmount: "",
        note: "",
        supportingDocuments: [],
        topUpPopUp: false,
        showDescription: false
      }
    },

    methods: {
    ...mapActions("paymentRequest", ["requestTopUp"]),

      handleTopUpPopUp() {
        this.errors.clear();
        this.topUpPopUp = !this.topUpPopUp;
      },

      fileUpdate(files) {
        this.supportingDocuments = files;
      },

      handleCancel() {
        this.handleTopUpPopUp();
      },

      async handleSubmit() {
        const valid = await this.$validator.validate();

        if (!valid) {
          return;
        }

        this.$vs.loading();

        const formData = new FormData();

        formData.append("amount", this.additionalFundAmount);
        formData.append("note", this.note);
        formData.append("paymentRequestId", this.prId);

        for (let i = 0; i < this.supportingDocuments.length; i++) {
          formData.append("attachments", this.supportingDocuments[i]);
        }

        const obj = {
          obj: formData,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        await this.requestTopUp(obj).then((result) => {
          this.$vs.loading.close();
          this.showMessage("Success", "Funding requested successfully.", "success", "icon-check-circle");
          this.topUpPopUp = false;
          this.$emit("update");
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Failed", ex.data.message, "danger", "icon-times-circle");
        });
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup");
        }
      },

      auto_grow(elem) {
        let element = document.getElementById(elem);

        if (element) {
          element.style.height = "5px";
          element.style.height = (element.scrollHeight) + "px";
        }
      },
    },

    created() {
      VeeValidate.Validator.extend("requiredAmount", {
        getMessage: (field) => "Additional funds field is required",
        validate: (value) => !!value,
      });
    },

    watch: {
      topUpPopUp(val) {
        this.addPopUpClass(val);

        if (!val) {
          this.additionalFundAmount = "";
          this.note = "";
          this.supportingDocuments = [];
          this.$refs.attachmentComponent.clearFiles();
          this.errors.clear();
        }
      }
    },
  }
</script>

