<template>
  <div>
    <div class="req-details" v-if="paymentRequestDetail">
      <main-details
        :paymentRequestDetail.sync="paymentRequestDetail"
        :calculations="calculations"
        :isAdminLogin.sync="isAdminLogin"
        :markPaymentAsCancelled="markPaymentAsCancelled"
        :resendPaymentRequest="resend"
        :remainingAmount="remainingAmount"
        :productConfigType.sync="productConfigType"
        @update="updateContent"
        :updatePaymentRequest="updatePRStatus"
      />
      <Terms
        v-if="showTermsCard"
        class="mb-6 custom-terms"
        ref="termsValidation"
        :autoDebit.sync="autoDebit"
        :payment-request-id="paymentRequestDetail._id"
        :show-buttons="true"
        :termsDetail.sync="termsDetail"
        @declineRequest="markPaymentAsCancelled"
        @update="updateContent"
      />
      <transactions-table v-if="readyToRender && paymentRequestDetail.transactions && paymentRequestDetail.transactions.length" :transactions="paymentRequestDetail.transactions" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { RRule } from "rrule";
import MainDetails from "./MainDetails/MainDetails.vue";
import TransactionsTable from "./transactionsTable.vue";
import Terms from "../../pages/paymentRequests/terms.vue";

export default {
  components: {
    MainDetails,
    Terms,
    TransactionsTable,
  },

  data() {
    return {
      autoDebit: false,
      calculations: null,
      canRefund: false,
      isAdminLogin: false,
      partnerId: JSON.parse(localStorage.getItem("user"))._id,
      paymentRequestDetail: {},
      paymentSchedules: "",
      productConfigType: "",
      productFieldsDetail: {},
      readyToRender: false,
      remainingAmount: 0,
      showMore: false,
      showTermsCard: false,
      termsDetail: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    merchantId() {
      return (this.user.userType === "admin") ? this.user._id : this.user.partnerId;
    }
  },

  methods: {
    ...mapActions("paymentRequest", ["cancelPaymentRequest", "fetchPaymentRequestDetailById", "resendPaymentRequest", "updatePaymentRequest"]),
    ...mapActions("payment", ["getQuotationDataForPayLater"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("legals", ["fetchProductLegalsById"]),

    prepareSchedule() {
      let rule = {
        dtstart: new Date(this.paymentRequestDetail.frequency.startDate),
        freq: this.paymentRequestDetail.frequency.every === "week" ? RRule.WEEKLY : RRule.MONTHLY,
        interval: parseInt(this.paymentRequestDetail.frequency.interval),
      };

      if (this.paymentRequestDetail.frequency.endType === "by") {
        rule.until = this.paymentRequestDetail.frequency.endDate ? this.paymentRequestDetail.frequency.endDate : new Date();
      } else {
        rule.count = this.paymentRequestDetail.frequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestDetail.frequency.endAfterNumberOfPayments) : 1;
      }

      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentSchedules = rule.all();
    },

    async fetchDetail(id) {
      this.$vs.loading();
      await this.fetchPaymentRequestDetailById(id).then(async (result) => {
        this.paymentRequestDetail = result.data.data;

        if (this.paymentRequestDetail.selectedPaymentPlan) {
          this.productConfigType = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail.selectedPaymentPlan, "currentPlan.productConfigType", "THREE_PARTY");
        } else if (this.paymentRequestDetail.payLaterPlan && this.paymentRequestDetail.payLaterPlan.length) {
          this.productConfigType = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail.payLaterPlan[0], "currentPlan.productConfigType", "THREE_PARTY");
        } else {
          this.productConfigType = "THREE_PARTY";
        }

        this.canRefund = true;

        if (this.paymentRequestDetail.requestOptions.includes("pay-later")) {
          this.getCalculations();
        }

        if (this.paymentRequestDetail.requestType === "recurring") {
          this.prepareSchedule();
        }

        if (!this.paymentRequestDetail.metadata && this.paymentRequestDetail.transactions) {
          const refundedTransactions = this.paymentRequestDetail.transactions.filter((transaction) => transaction.paymentStatus === "Refunded");
          const actualTransaction = this.paymentRequestDetail.transactions.find((transaction) => transaction.paymentStatus === "Completed");

          if (refundedTransactions && refundedTransactions.length) {
            this.refundedAmount = refundedTransactions.map((item) => Math.abs(parseFloat(item.amount))).reduce((prev, next) => prev + next, 0);
            this.remainingAmount = parseFloat(actualTransaction.amount) - this.refundedAmount;
          }
        }

        if (this.paymentRequestDetail.franchisorId && this.merchantId != this.paymentRequestDetail.franchisorId && this.paymentRequestDetail.paymentStatus === "Pending") {
          const selectedPaymentPlan = this.paymentRequestDetail.selectedPaymentPlan;
          const legals = await this.fetchProductLegalsById({id : selectedPaymentPlan.currentPlan.planId, customer: true });

          this.termsDetail = legals.data.data && legals.data.data.legals && legals.data.data.legals.legals ? legals.data.data.legals.legals : [];
          this.autoDebit = selectedPaymentPlan && selectedPaymentPlan.currentPlan && selectedPaymentPlan.currentPlan.autoDebit ? selectedPaymentPlan.currentPlan.autoDebit : false;

          this.showTermsCard = true;
        } else {
          this.showTermsCard = false;
        }

        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.response.data.title,
          text: ex.response.data.message,
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-times",
        });

        if (ex.response.status === 404) {
          this.$router.go(-1);
        } else if (ex.response.status === 403) {
          this.$router.push({ name: "partner-dashboard" });
        }
      });

      this.checkIfAdminLogin();
      this.readyToRender = true;
    },
    getCalculations() {
      this.getQuotationDataForPayLater({ paymentRequestId: this.paymentRequestDetail._id }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        this.calculations = {
          total,
          disbursement,
          schedule
        };
      });
    },

    toggleShowMore() {
      this.showMore = !this.showMore;
    },

    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;

      if (loggedUser.adminId && loggedUser.adminId != "" && loggedUser.loginBy === "flip admin") {
        this.isAdminLogin = true;
      }
    },

    async markPaymentAsCancelled(values) {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user.adminId ? this.user.adminId : null },
      };

      if (values && values.type && values.type == "repay") {
        data.data.type = values.type;
        data.data.repayTerms = values.repayTerms;
        data.data.paymentMethodTerms = values.paymentMethodTerms;
      }

      this.$vs.loading();
      return await this.cancelPaymentRequest(data).then(result => {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "success",
          title: result.data.title || "Payment request",
          text: result.data.message,
        })
        this.$router.push({ name: "partner-dashboard" });
      }).catch(ex => {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: "Payment request",
          text: ex.response.data.message,
          fixed: true
        });
      });
    },

    async resend() {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user.adminId ? this.user.adminId : null, sendOnlyComms: this.paymentRequestDetail.paymentStatus == "Pending" },
      };

      return await this.resendPaymentRequest(data).then((response) => {
        if (response.data) {
           this.$vs.notify({
            color: "success",
            title: "Payment request",
            text: "Payment request resent successfully.",
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Payment request",
            text: "We are unable to process your request at this time.",
          });
        }

        this.$router.push({ name: "partner-dashboard" });
      }).catch((ex) => {
        this.$vs.notify({
          color: "danger",
          title: "Payment request",
          text: "We are unable to process your request at this time.",
        });
        this.$router.push({ name: "partner-dashboard" });
      });
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },

    updateContent() {
      this.fetchDetail(this.$route.params.id);
    },

    async updatePRStatus() {
      const data = {
        id: this.$route.params.id,
        processedByAdmin: this.user && this.user.adminId && this.user.loginBy ? this.user.adminId : null
      };
      this.$vs.loading();

      return await this.updatePaymentRequest(data).then((response) => {
        this.$vs.loading.close();
        if (response.data) {
          this.showToastMessage("Payment request", "Payment request completed successfully", "success");
        } else {
          this.showToastMessage("Payment request", "Payment request update error", "error");
        }
        this.fetchDetail(this.$route.params.id);
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Payment request", "We are unable to process your request at this time", "error");
      });
    },
  },

  mounted() {
    this.fetchDetail(this.$route.params.id);
    this.checkAdminLogin();
  },
};
</script>

