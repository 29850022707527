<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Legals</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <a class="w-max text-base" @click="goToLegals('DASHBOARD', privacy)"> Privacy Policy </a>
      <a class="w-max text-base" @click="goToLegals('PAY_LATER_LEGAL', terms)"> Terms & Conditions </a>
      <a class="w-max text-base" v-if="paymentMethodTerms" @click="goToLegals('DIRECT_DEBIT', paymentMethodTerms)">Payment terms & Conditions</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Legal",
  props: ["paymentMethodTerms", "privacy", "terms"],
  methods: {
    goToLegals(type, id) {
      const routeParams = {
        params: {
          id: id,
        },
        name: "TermsAndConditions",
        query: { type: type, fullDocument: "true" },
      };

      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    }
  },
};
</script>
