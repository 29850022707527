<template>
  <vs-popup class="holamundo" title="Edit disbursement date" :active.sync="disbursementPopUp">
    <p class="mb-6">You can schedule disbursement of funds from Rello to another date in the future, if the disbursement has not yet occurred. Available dates are indicated in the date picker below.</p>
    <label>Reschedule disbursement to:</label>
    <div class="input-datepicker w-1/3 full-height mt-2">
      <date-picker
        name="disbursementDate"
        id="disbursementDate"
        v-model="disbursementDate"
        lang="en"
        type="date"
        placeholder="DD-MM-YYYY"
        input-class="datepicker-input w-full"
        format="DD-MM-YYYY"
        :disabled-days="disabledDate"
        :defaultViewDate="displayedMonth"
        @change="handleDateChange($event)"
        @focus="setDisplayedMonth"
      ></date-picker>
      <span class="text-danger text-sm" v-show="errors.has('disbursementDate')">{{ errors.first("disbursementDate") }}</span>
    </div>
    <div class="popup-action-btn-container pt-6 sticky bottom-0 bg-white">
      <div class="popup-action-btn pt-8 flex justify-end">
        <vs-button class="mr-5" action-btn @click="handleSubmit" size="large">Continue</vs-button>
        <vs-button @click="handleCancel" action-btn color="danger" size="large">Cancel</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment-timezone";
  import DatePicker from "vue2-datepicker";

  export default {
    components: {
      DatePicker
    },

    props: {
      paymentRequest: {
        type: Object
      },
      prId: {
        type: String
      }
    },

    data() {
      return {
        disbursementDate: "",
        disbursementPopUp: false,
        timezone: "Australia/Sydney",
        displayedMonth: null, // Will hold the date used to display the calendar
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["editDisbursementDate"]),

      disabledDate(date) {
        const calendarDate = moment(date).format("YYYY-MM-DD");
        const today = moment.tz(this.timezone).startOf("day").format("YYYY-MM-DD");

        // Get the date 60 days from today/openingDate in the specified timezone
        const openingDate = (this.paymentRequest && this.paymentRequest.openingDate) ? this.paymentRequest.openingDate : today;
        const dateHere = moment.tz(moment(openingDate), this.timezone).startOf("day").add(60, "days").format("YYYY-MM-DD");
        // Compare the provided date with today's date and the date 60 days from now
        return (moment(calendarDate).isBefore(today) || moment(calendarDate).isAfter(dateHere));
      },

      handleDisbursementPopUp() {
        this.errors.clear();
        this.disbursementPopUp = !this.disbursementPopUp;
      },

      handleCancel() {
        this.handleDisbursementPopUp();
      },

      async handleSubmit() {
        if (!this.disbursementDate || (this.disbursementDate == "")) {
          this.errors.add({
            field: "disbursementDate",
            msg: "The date field is required",
          });

          return;
        }

        this.$vs.loading();

        const payload = {
          paymentRequestId: this.prId,
          disbursementDate: moment(this.disbursementDate).format("YYYY-MM-DD")
        };

        await this.editDisbursementDate(payload).then((result) => {
          this.$vs.loading.close();
          this.showMessage("Success", "Disbursement date updated successfully.", "success", "icon-check-circle");
          this.disbursementPopUp = false;
          this.$emit("update");
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Failed", ex.data.message, "danger", "icon-times-circle");
        });
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup", "disbursement-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup", "disbursement-popup");
        }
      },

      setDisplayedMonth() {
        // If a date is selected, set the calendar to display its month
        if (this.paymentRequest && this.paymentRequest.disbursementDate) {
          this.disbursementDate = this.paymentRequest.disbursementDate;
          this.displayedMonth = moment(this.paymentRequest.disbursementDate, "YYYY-MM-DD").toDate();
        }
      },

      handleDateChange(val) {
        if (val && this.errors.has("disbursementDate")) {
          this.errors.remove("disbursementDate");
        }
      }
    },

    mounted() {
      // Set the initial displayed month based on the selected date
      this.setDisplayedMonth();
    },

    watch: {
      disbursementPopUp(val) {
        this.addPopUpClass(val);

        if (val) {
          this.disbursementDate = this.paymentRequest.disbursementDate;
        } else {
          this.disbursementDate = "";
        }
      },

      paymentRequest(val) {
        if (val) {
          this.disbursementDate = this.paymentRequest.disbursementDate;
        }
      }
    },
  }
</script>

<style scoped>
.disbursement-popup .vs-popup--content {
  min-height: 45vh !important;
}
</style>

