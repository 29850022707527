<template>
  <vs-popup class="holamundo" title="Edit request" :active.sync="prEditPopUp">
    <p>You can edit the fields below while this request is in a "pending" status (use "Resend request" to send a new SMS/email if required). Note that if the request was originally created via API/integration, changing the amount may cause issues reconciling payments back into your integrated system.</p>
    <div class="w-full mt-8">
      <div class="grid grid-cols-2 w-full gap-x-8">
        <div class="mb-4 mr-0">
          <div class="flex">
            <label for="amount" class="w-full text-base font-light text-dark-blue block pb-1">Amount <span class="mid-blue">*</span></label>
            <a @click="handleRevert('amount')" class="flex gap-2 no-underline">
              <vs-icon icon-size="small" class="refresh-icon mt-1">refresh</vs-icon>
              Revert
            </a>
          </div>
          <div class="flex items-center relative">
            <span class="pr-1 absolute l-0 t-0 text-dark-blue">$</span>
            <money class="moneyPR w-full pl-5 font-light" v-model="amount" name="amount" :precision="2" :placeholder="'Amount'" id="amount" v-validate="'amount'" />
          </div>
          <span class="text-danger text-sm" v-if="errors.has('amount')">{{ errors.first("amount") }}</span>
          <span class="text-danger text-sm" v-else-if="showWarning != ''">{{ showWarning }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 lg:flex-row w-full gap-x-8 mt-6">
      <div class="w-full mr-0">
        <div class="flex">
          <label for="amount" class="w-full text-base font-light text-dark-blue block pb-1">Customer name</label>
          <a @click="handleRevert('payeeName')" class="flex gap-2 no-underline">
            <vs-icon icon-size="small" class="refresh-icon mt-1">refresh</vs-icon>
            Revert
          </a>
        </div>
        <div class="flex items-center relative">
          <vs-input v-model="payeeName" name="payeeName" data-vv-as="customer name" class="no-max-width" />
        </div>
      </div>
      <div class="w-full mr-0">
        <div class="flex">
          <label for="amount" class="w-full text-base font-light text-dark-blue block pb-1">Customer email</label>
          <a @click="handleRevert('payeeEmail')" class="flex gap-2 no-underline">
            <vs-icon icon-size="small" class="refresh-icon mt-1">refresh</vs-icon>
            Revert
          </a>
        </div>
        <div class="flex items-center relative">
          <vs-input
            v-model="payeeEmail"
            name="payeeEmail"
            v-validate="'email'"
            data-vv-as="customer email"
            class="no-max-width"
          />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('payeeEmail')">{{ errors.first("payeeEmail") }}</span>
      </div>
      <div class="w-full mt-8">
        <div class="flex">
          <label for="amount" class="w-full text-base font-light text-dark-blue block pb-1">Customer mobile</label>
          <a @click="handleRevert('payeePhone')" class="flex gap-2 no-underline">
            <vs-icon icon-size="small" class="refresh-icon mt-1">refresh</vs-icon>
            Revert
          </a>
        </div>
        <div class="flex items-center relative">
          <vs-input
            name="payeePhone"
            v-mask="'#### ### ###'"
            v-model="payeePhone"
            data-vv-as="customer mobile"
            v-validate="{
              regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
            }"
            data-vv-validate-on.native="blur"
            class="no-max-width"
          />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('payeePhone')">{{ errors.first("payeePhone") }}</span>
      </div>
    </div>
    <div class="popup-action-btn-container flex justify-end">
      <vs-button class="mr-5" action-btn @click="handleSubmit" size="large">Update</vs-button>
      <vs-button @click="handleCancel" action-btn color="danger" size="large">Cancel</vs-button>
    </div>
  </vs-popup>
</template>

<script>
  import { mapActions } from "vuex";
  import { Money } from "v-money";
  import { mask } from "vue-the-mask";
  import VeeValidate from "vee-validate";

  export default {
    components: { Money },

    props: {
      paymentRequest: {
        type: Object
      },
      prId: {
        type: String
      },
    },
    directives: { mask },

    data() {
      return {
        prEditPopUp: false,
        amount: "",
        payeeEmail: "",
        payeePhone: "",
        payeeName: "",
        showWarning: ""
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["updatePaymentRequestDetail"]),

      handleRevert(field) {
        if (field == "amount") {
          this[field] = this.paymentRequest.amount && this.paymentRequest.amount.$numberDecimal ? parseFloat(this.paymentRequest.amount.$numberDecimal) : this.paymentRequest.amount;
        } else {
          this[field] = this.paymentRequest[field];
        }
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup");
        }
      },

      handleCancel() {
        this.handlePrEditPopUp();
      },

      handlePrEditPopUp() {
        this.errors.clear();
        this.prEditPopUp = !this.prEditPopUp;
      },

      async handleSubmit() {
        const valid = await this.$validator.validateAll();

        if (!valid) {
          return;
        }

        if (this.showWarning != "") {
          return;
        }

        const payload = {
          payeeName: this.payeeName,
          payeeEmail: this.payeeEmail,
          payeePhone: this.payeePhone,
          amount: this.amount,
        };

        const obj = {
          data: payload,
          id: this.prId,
        };

        this.$vs.loading();

        await this.updatePaymentRequestDetail(obj).then(result => {
          this.$vs.loading.close();
          this.showMessage("Success", "Payment request updated successfully.", "success", "icon-check-circle");
          this.prEditPopUp = false;
          this.$emit("update");
        }).catch(ex => {
          this.$vs.loading.close();

          if (ex.response.status == 422) {
            ex.response.data.details.forEach(item => {
              if (!this.errors.items.find(error => error.field == item.field)) {
                const key = Object.keys(item)[0];

                this.errors.add({
                  field: key,
                  msg: item[key],
                });
              }
            });
          }

          this.showMessage("Failed", ex.response.data.message, "danger", "icon-times-circle");
        });
      },

      showMessage(title, message, color, icon) {
        this.$vs.notify({
          title: title,
          text: message,
          color: color,
          iconPack: "feather",
          position: "top-right",
          icon: icon,
        });
      },
    },

    watch: {
      prEditPopUp(val) {
        this.addPopUpClass(val);

        if (!val) {
          this.amount = "";
          this.payeeEmail = "";
          this.payeePhone = "";
          this.payeeName = "";
          this.errors.clear();
        } else {
          this.amount = this.paymentRequest.amount && this.paymentRequest.amount.$numberDecimal ? parseFloat(this.paymentRequest.amount.$numberDecimal) : this.paymentRequest.amount;
          this.payeeEmail = (this.paymentRequest && this.paymentRequest.payeeEmail) ? this.paymentRequest.payeeEmail : "";
          this.payeePhone = (this.paymentRequest && this.paymentRequest.payeePhone) ? this.paymentRequest.payeePhone : "";
          this.payeeName = (this.paymentRequest && this.paymentRequest.payeeName) ? this.paymentRequest.payeeName : "";
        }
      },

      amount :_.debounce(async function (newVal) {
        if (this.paymentRequest && this.paymentRequest.payLaterPlan && this.paymentRequest.payLaterPlan.length) {
          const plan = this.paymentRequest.payLaterPlan[0].currentPlan;

          if (newVal && newVal > 0 && (newVal < parseFloat(plan.minimumAmount) || newVal > parseFloat(plan.maximumAmount))) {
            this.showWarning = `Amount should be between ${plan.minimumAmount} and ${plan.maximumAmount}`;
          } else {
            this.showWarning = "";
          }
        } else {
          this.showWarning = "";
        }

      }, 600),
    },

    created() {
      VeeValidate.Validator.extend("amount", {
        getMessage: (field) => "Amount field is required",
        validate: (value) => !!value,
      });
    },
  }
</script>

